import windEastPng from 'src/assets/images/weather-gauge/wind-direction/wind_east.png'
import windEsePng from 'src/assets/images/weather-gauge/wind-direction/wind_ese.png'
import windNnePng from 'src/assets/images/weather-gauge/wind-direction/wind_nne.png'
import windNorthPng from 'src/assets/images/weather-gauge/wind-direction/wind_north.png'
import windSouthPng from 'src/assets/images/weather-gauge/wind-direction/wind_south.png'
import windSePng from 'src/assets/images/weather-gauge/wind-direction/wind_se.png'
import windSswPng from 'src/assets/images/weather-gauge/wind-direction/wind_ssw.png'
import windWestPng from 'src/assets/images/weather-gauge/wind-direction/wind_west.png'
import windWswPng from 'src/assets/images/weather-gauge/wind-direction/wind_wsw.png'
import windEnePng from 'src/assets/images/weather-gauge/wind-direction/wind_ene.png'
import windNePng from 'src/assets/images/weather-gauge/wind-direction/wind_ne.png'
import windNnwPng from 'src/assets/images/weather-gauge/wind-direction/wind_nnw.png'
import windNwPng from 'src/assets/images/weather-gauge/wind-direction/wind_nw.png'
import windSsePng from 'src/assets/images/weather-gauge/wind-direction/wind_sse.png'
import windSwPng from 'src/assets/images/weather-gauge/wind-direction/wind_sw.png'
import windWnwPng from 'src/assets/images/weather-gauge/wind-direction/wind_wnw.png'

const windImageList = [
  windNorthPng,
  windNnePng,
  windNePng,
  windEnePng,
  windEastPng,
  windEsePng,
  windSePng,
  windSsePng,
  windSouthPng,
  windSswPng,
  windSwPng,
  windWswPng,
  windWestPng,
  windWnwPng,
  windNwPng,
  windNnwPng
]

export const getWindImage = (windDirection: number) => {
  return windImageList[Math.floor(windDirection / 22.5)]
}

const windDirectionTextList: Record<number, string> = [
  '北',
  '北北東',
  '北東',
  '東北東',
  '東',
  '東南東',
  '南東',
  '南南東',
  '南',
  '南南西',
  '南西',
  '西南西',
  '西',
  '西北西',
  '北西',
  '北北西'
]

export const getWindDirectionText = (windDirection: number) => {
  return windDirectionTextList[Math.floor(windDirection / 22.5)]
}
