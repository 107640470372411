import { forwardRef } from 'react'

export const Presenter = forwardRef<HTMLVideoElement, { url: string }>(
  ({ url }, ref) => {
    return (
      <video
        ref={ref}
        style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
        src={url}
        muted={false}
        playsInline
        crossOrigin="anonymous"
        disablePictureInPicture
        loop
      />
    )
  }
)
