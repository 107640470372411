import { useState, useRef, useCallback } from 'react'
import { Client } from 'paho-mqtt'
import { createEndpoint } from './utils'
import { usePrefetch } from 'src/hooks'

const REGION_NAME = 'ap-northeast-1'
const AWS_IOT_ENDPOINT = 'a2vcuhgxqo3y5y-ats.iot.ap-northeast-1.amazonaws.com'

const generateClientId = () => Math.random().toString(36).substring(7)

type Payload = {
  v: number
  n: number
  displayDate: Date
}

type Props = {
  slideIndex: number
}

export const useSubscribe = ({ slideIndex }: Props) => {
  const [data, setData] = useState<Payload>()
  const clientRef = useRef<Client>()

  const prefetch = useCallback(() => {
    if (clientRef.current?.isConnected()) {
      clientRef.current.disconnect()
    }

    const endpoint = createEndpoint({
      regionName: REGION_NAME,
      awsIotEndpoint: AWS_IOT_ENDPOINT,
      accessKey: import.meta.env.VITE_AWS_ACCESS_KEY ?? '',
      secretKey: import.meta.env.VITE_AWS_SECRET_KEY ?? ''
    })
    const clientId = generateClientId()

    const client = new Client(endpoint, clientId)
    clientRef.current = client

    client.connect({
      useSSL: true,
      timeout: 3,
      mqttVersion: 4,
      onSuccess: () => {
        console.log('onConnect')
        // TODO: device_codeを使用して購読
        client.subscribe('envis/data/1/000A7A4D')
      }
    })

    client.onMessageArrived = (message) => {
      const data = JSON.parse(message.payloadString) as Omit<
        Payload,
        'displayDate'
      >
      setData({ ...data, displayDate: new Date() })
      console.log('onMessageArrived', data)
    }

    client.onConnectionLost = function (e) {
      console.log('onConnectionLost', e)
    }

    // return () => {
    //   if (client.isConnected()) {
    //     client.disconnect()
    //   }
    // }
  }, [])

  usePrefetch({
    slideIndex,
    prefetch
  })

  return { data }
}
