import 'swiper/css'
import { QueryClient } from '@tanstack/react-query'
import { AuthProvider } from 'src/contexts/AuthContext'
import { RouterProvider } from 'react-router-dom'
import { router } from './Router'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { ToastProvider } from './hooks'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst',
      cacheTime: Infinity,
      staleTime: Infinity
    }
  }
})

const persister = createSyncStoragePersister({
  storage: window.localStorage
})

function App() {
  useRegisterSW({})

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <AuthProvider>
        <ToastProvider>
          <RouterProvider router={router} />
        </ToastProvider>
      </AuthProvider>
      {/* NOTE: ReactQueryDevtoolsは本番ビルド時に除外される */}
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  )
}

export default App
