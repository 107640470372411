import { useGetNoiseVibration } from 'generated/backendComponents'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'
import { useSubscribe } from './hooks'
import { useMemo } from 'react'
import { ExtendedNoiseVibration } from './types'

type Props = {
  slideIndex: number
  contentId: number
}

export const NoisePage = ({ contentId, slideIndex }: Props) => {
  const { data, refetch } = useGetNoiseVibration(
    {
      pathParams: { contentId: String(contentId) }
    },
    { enabled: false }
  )
  usePrefetch({ prefetch: refetch, slideIndex })

  const { isLandScape } = useWindowResize()

  const { data: noiseData } = useSubscribe({ slideIndex })

  const extendedNoiseVibration = useMemo<ExtendedNoiseVibration>(() => {
    // TODO: APIがまだなので一部仮実装
    return {
      noiseValue: (noiseData?.n ?? 0).toFixed(data?.show_decimal ? 1 : 0),
      vibrationValue: (noiseData?.v ?? 0).toFixed(data?.show_decimal ? 1 : 0),
      displayDate: noiseData?.displayDate
        ? noiseData?.displayDate.toString()
        : undefined
    }
  }, [data, noiseData])

  // TODO: 設定値を取得
  const type = 'default' as any

  // if (!data) return <Loading />

  return isLandScape ? (
    <HorizontalPresenter {...{ type, data: extendedNoiseVibration }} />
  ) : (
    <VerticalPresenter {...{ type, data: extendedNoiseVibration }} />
  )
}
