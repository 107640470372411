import SafetyPng from 'src/assets/images/heatstroke/illust/safety.png'
import Safety2Png from 'src/assets/images/heatstroke/illust/safety@2x.png'
import AttentionPng from 'src/assets/images/heatstroke/illust/attention.png'
import Attention2Png from 'src/assets/images/heatstroke/illust/attention@2x.png'
import VigilancePng from 'src/assets/images/heatstroke/illust/vigilance.png'
import Vigilance2Png from 'src/assets/images/heatstroke/illust/vigilance@2x.png'
import SevereVigilancePng from 'src/assets/images/heatstroke/illust/severe-vigilance.png'
import SevereVigilance2Png from 'src/assets/images/heatstroke/illust/severe-vigilance@2x.png'
import DangerPng from 'src/assets/images/heatstroke/illust/danger.png'
import Danger2Png from 'src/assets/images/heatstroke/illust/danger@2x.png'

import TextAttentionPng from 'src/assets/images/heatstroke/text_attention.png'
import TextAttention2Png from 'src/assets/images/heatstroke/text_attention@2x.png'
import TextDangerPng from 'src/assets/images/heatstroke/text_danger.png'
import TextDanger2Png from 'src/assets/images/heatstroke/text_danger@2x.png'
import TextSevereVigilancePng from 'src/assets/images/heatstroke/text_s-vigilance.png'
import TextSevereVigilance2Png from 'src/assets/images/heatstroke/text_s-vigilance@2x.png'
import TextVigilancePng from 'src/assets/images/heatstroke/text_vigilance.png'
import TextVigilance2Png from 'src/assets/images/heatstroke/text_vigilance@2x.png'
import TextSafetyPng from 'src/assets/images/heatstroke/text_safety.png'
import TextSafety2Png from 'src/assets/images/heatstroke/text_safety@2x.png'
import { Util } from 'src/utils'

export const getCharacterImagesByWbgt = (wbgt: number) => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return [DangerPng, Danger2Png]
    case 4:
      return [SevereVigilancePng, SevereVigilance2Png]
    case 3:
      return [VigilancePng, Vigilance2Png]
    case 2:
      return [AttentionPng, Attention2Png]
    case 1:
      return [SafetyPng, Safety2Png]
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

export const getTextImagesByWbgt = (wbgt: number) => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return [TextDangerPng, TextDanger2Png]
    case 4:
      return [TextSevereVigilancePng, TextSevereVigilance2Png]
    case 3:
      return [TextVigilancePng, TextVigilance2Png]
    case 2:
      return [TextAttentionPng, TextAttention2Png]
    case 1:
      return [TextSafetyPng, TextSafety2Png]
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}
