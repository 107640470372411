import iconNoisePng from 'src/assets/images/noise-meter/eva/icon_noise.png'
import iconVibrationPng from 'src/assets/images/noise-meter/eva/icon_vibration.png'
import baseBottomPng from 'src/assets/images/noise-meter/eva/base_bottom.png'
import { formatDate } from 'src/utils/formatDate'
import { FloatingText } from '../../FloatingText'
import { ExtendedNoiseVibration } from '../../types'

export const ExamplePageEva = ({
  displayDate,
  noiseValue,
  vibrationValue
}: ExtendedNoiseVibration) => {
  return (
    <div className="noiseMeter eva vertical">
      <header className="header">
        <h1 className="header__title">現在の騒音・振動</h1>
        <p className="header__date">
          {displayDate && formatDate(new Date(displayDate), 'M月d日H:mm時点')}
        </p>
      </header>
      <FloatingText>
        日本橋一丁目中地区第一種市街地再開発事業日本橋一丁目中地区第一種市街地再開発事業
      </FloatingText>
      <div className="dataWrapper">
        <div className="noiseWrapper">
          <h2 className="noiseWrapper__title">
            <img src={iconNoisePng} alt="騒音" />
          </h2>
          <div className="valueWrapper">
            <p className="valueWrapper__strings">{noiseValue}</p>
            <p className="valueWrapper__db">dB</p>
          </div>
        </div>

        <div className="vibrationWrapper">
          <h2 className="vibrationWrapper__title">
            <img src={iconVibrationPng} alt="振動" />
          </h2>
          <div className="valueWrapper">
            <p className="valueWrapper__strings">{vibrationValue}</p>
            <p className="valueWrapper__db">db</p>
          </div>
        </div>
      </div>
      <div className="bottom">
        <img src={baseBottomPng} alt="" />
      </div>
    </div>
  )
}
