const getDangerLevel = (wbgt: number): 1 | 2 | 3 | 4 | 5 => {
  if (wbgt >= 31) return 5
  if (wbgt >= 28) return 4
  if (wbgt >= 25) return 3
  if (wbgt >= 21) return 2
  return 1
}

const getClassNameByWbgt = (wbgt: number) => {
  const dangerLevel = getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return 'danger'
    case 4:
      return 's-vigilance'
    case 3:
      return 'vigilance'
    case 2:
      return 'attention'
    case 1:
      return 'safety'
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

const getCaptionByWbgt = (wbgt: number) => {
  const dangerLevel = getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return '危険'
    case 4:
      return '厳重警戒'
    case 3:
      return '警戒'
    case 2:
      return '注意'
    case 1:
      return 'ほぼ安全'
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

/**
 * https://www.wbgt.env.go.jp/sp/wbgt.php の「運動に関する指針」を参考
 */
const getDescriptionByWbgt = (wbgt: number) => {
  const dangerLevel = getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return (
        <>
          特別の場合以外は運動を中止する。
          <br />
          特に子どもの場合には中止すべき。
        </>
      )
    case 4:
      return (
        <>
          熱中症の危険性が高いので、激しい運動や持久走など体温が上昇しやすい運動は避ける。
          10～20分おきに休憩をとり水分・塩分の補給を行う。
          <br />
          暑さに弱い人※は運動を軽減または中止。
        </>
      )
    case 3:
      return (
        <>
          熱中症の危険が増すので、積極的に休憩をとり適宜、水分・塩分を補給する。
          <br />
          激しい運動では、30分おきくらいに休憩をとる。
        </>
      )
    case 2:
      return (
        <>
          熱中症による死亡事故が発生する可能性がある。
          <br />
          熱中症の兆候に注意するとともに、運動の合間に積極的に水分・塩分を補給する。
        </>
      )
    case 1:
      return (
        <>
          通常は熱中症の危険は小さいが、適宜水分・塩分の補給は必要である。
          <br />
          市民マラソンなどではこの条件でも熱中症が発生するので注意。
        </>
      )
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}

export const wbgt = {
  getDangerLevel,
  getClassNameByWbgt,
  getCaptionByWbgt,
  getDescriptionByWbgt
}
