import { createBrowserRouter } from 'react-router-dom'
import { Auth } from './pages/Auth'
import { Slideshow } from './pages/slideshow'

export const router = createBrowserRouter([
  {
    path: '/auth/:code',
    element: <Auth />
  },
  {
    path: '/slideshow',
    element: <Slideshow />
  }
])
