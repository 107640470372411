import { Icc } from 'generated/backendSchemas'
import { Util } from 'src/utils'
import { getWindDirectionText, getWindImage } from '../util'

export const ExamplePageDefault = ({
  average_wind_speed,
  barometric_pressure,
  highest,
  humidity,
  lowest,
  max_wind_speed,
  rainfall,
  rainfall_24,
  rainfall_day,
  sunrise_time,
  sunset_time,
  temperature,
  uvi,
  wbgt_value,
  wind_direction
}: Icc) => {
  return (
    <div className="Weather_gauge horizontal">
      <ul className="WeatherGaugeList">
        <li className="WeatherGaugeList__Item">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Temp">
            気温
          </h2>
          <p className="WeatherGaugeList__Value">
            {temperature}
            <span className="WeatherGaugeList__Value--Unit">℃</span>
          </p>
          <div className="WeatherGaugeList__Other">
            <p className="WeatherGaugeList__Other--Min">
              本日の最低気温
              <span className="WeatherGaugeList__Other--Numbers">{lowest}</span>
              ℃
            </p>
            <p className="WeatherGaugeList__Other--Max">
              本日の最高気温
              <span className="WeatherGaugeList__Other--Numbers">
                {highest}
              </span>
              ℃
            </p>
          </div>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Humidity">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Humidity">
            湿度
          </h2>
          <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Humidity">
            {humidity}
            <span className="WeatherGaugeList__Value--Unit">%</span>
          </p>
        </li>

        <li className="WeatherGaugeList__Item">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Rainfall">
            雨量
          </h2>
          <p className="WeatherGaugeList__Value--Unit">
            1時間雨量
            <span className="WeatherGaugeList__Value--Numbers">{rainfall}</span>
            <span className="WeatherGaugeList__Value--Unit">mm</span>
          </p>
          <div className="WeatherGaugeList__Other">
            <p className="WeatherGaugeList__Other--Day">
              24時間雨量
              <span className="WeatherGaugeList__Other--Numbers">
                {rainfall_24}
              </span>
              mm
            </p>
            <p className="WeatherGaugeList__Other--Day">
              1日雨量
              <span className="WeatherGaugeList__Other--Numbers">
                {rainfall_day}
              </span>
              mm
            </p>
          </div>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Wbgt">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--White WeatherGaugeList__Title--Wbgt">
            換算WBGT
          </h2>
          <div className="WeatherGaugeList__Content">
            <p className="WeatherGaugeList__Value WeatherGaugeList__Value--White">
              {wbgt_value}
              <span className="WeatherGaugeList__Value--Unit">℃</span>
            </p>
            <p className="WeatherGaugeList__Value--Wbgt">
              {wbgt_value && Util.wbgt.getCaptionByWbgt(wbgt_value)}
            </p>
          </div>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Speed">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Speed">
            風速
          </h2>
          <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Average">
            平均風速
            <span className="WeatherGaugeList__Value--Numbers">
              {average_wind_speed}
            </span>
            m/s
          </p>
          <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Maximum">
            最大風速
            <span className="WeatherGaugeList__Value--Numbers">
              {max_wind_speed}
            </span>
            m/s
          </p>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Direction">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Direction">
            風向き
          </h2>
          <div className="WeatherGaugeList__DirectionWrapper">
            <img
              src={getWindImage(wind_direction ?? 0)}
              alt=""
              className="WeatherGaugeList__Value WeatherGaugeList__DirectionIcon"
            />
            <div className="WeatherGaugeList__DirectionInner">
              <p className="WeatherGaugeList__Value WeatherGaugeList__Value--DirectionDegrees">
                {wind_direction ?? 0}°
              </p>
              <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Direction">
                {getWindDirectionText(wind_direction ?? 0)}
              </p>
            </div>
          </div>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Pressure">
          <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Pressure">
            気圧
          </h2>
          <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Pressure">
            {barometric_pressure}
            <span className="WeatherGaugeList__Value--Unit">hPa</span>
          </p>
        </li>

        <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Sunrise">
          <div className="WeatherGaugeList__SunriseWrapper">
            <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--Sunrise">
              日の出・日の入
            </h2>
            <div className="WeatherGaugeList__SunriseInner">
              <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Time">
                日の出
                <span className="WeatherGaugeList__Value--Numbers">
                  {sunrise_time}
                </span>
              </p>
              <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Time">
                日の入
                <span className="WeatherGaugeList__Value--Numbers">
                  {sunset_time}
                </span>
              </p>
            </div>
          </div>
          <div className="WeatherGaugeList WeatherGaugeList__Uvi">
            <div className="WeatherGaugeList__UviWrapper">
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--White WeatherGaugeList__Title--Uvi">
                UVI
              </h2>
              <div className="WeatherGaugeList__UviInner">
                <p className="WeatherGaugeList__Value WeatherGaugeList__Value--White">
                  {uvi}
                </p>
                {/* TODO: バックエンドに依頼 */}
                <p className="WeatherGaugeList__Value--Ja">非常に強い</p>
              </div>
            </div>
          </div>
        </li>

        <li className="WeatherGaugeList__Item">
          <p className="WeatherGaugeList__measurementPoint">
            日本橋一丁目中地区第一種市街地再開発事業
          </p>
          <p className="WeatherGaugeList__siteName">
            日本橋一丁目中地区第一種市街地再開発事業(現場名が入ります)
          </p>
          <time className="WeatherGaugeList__date">
            2<span>月</span>20<span>日</span>13:24<span>現在</span>
          </time>
        </li>
      </ul>
    </div>
  )
}
