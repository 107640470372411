import { useRef, useEffect } from 'react'
import { useSwiperSlide } from 'swiper/react'

type Props = {
  prefetch: () => any
  slideIndex: number
}

export const usePrefetch = ({ prefetch, slideIndex }: Props) => {
  const swiperSlide = useSwiperSlide()
  const cleanupRef = useRef<() => void>()

  useEffect(() => {
    const isFirstSlideAndActivated = slideIndex === 0 && swiperSlide.isActive
    if (!(isFirstSlideAndActivated || swiperSlide.isNext)) return
    const cleanup = prefetch()
    if (typeof cleanup === 'function') {
      cleanupRef.current = cleanup
    }
  }, [slideIndex, swiperSlide.isNext, swiperSlide.isActive, prefetch])

  useEffect(() => {
    if (!(cleanupRef.current && swiperSlide.isPrev)) return
    cleanupRef.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperSlide.isPrev, prefetch])
}
