import { HmacSHA256, SHA256, enc } from 'crypto-js'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { NoiseVibration } from 'generated/backendSchemas'

const getSignatureKey = (
  key: string,
  dateStamp: string,
  regionName: string,
  serviceName: string
) => {
  const kDate = HmacSHA256(dateStamp, 'AWS4' + key)
  const kRegion = HmacSHA256(regionName, kDate)
  const kService = HmacSHA256(serviceName, kRegion)
  const kSigning = HmacSHA256('aws4_request', kService)
  return kSigning
}

const sign = (key: any, msg: string) => {
  const hash = HmacSHA256(msg, key)
  return hash.toString(enc.Hex)
}

const sha256 = (msg: string) => {
  const hash = SHA256(msg)
  return hash.toString(enc.Hex)
}

export const createEndpoint = ({
  accessKey,
  awsIotEndpoint,
  regionName,
  secretKey
}: {
  regionName: string
  awsIotEndpoint: string
  accessKey: string
  secretKey: string
}) => {
  const utcTime = utcToZonedTime(new Date(), 'UTC')
  const dateStamp = format(utcTime, 'yyyyMMdd')
  const amzdate = dateStamp + 'T' + format(utcTime, 'HHmmss') + 'Z'

  const service = 'iotdevicegateway'
  const region = regionName
  const algorithm = 'AWS4-HMAC-SHA256'
  const method = 'GET'
  const canonicalUri = '/mqtt'
  const host = awsIotEndpoint

  const credentialScope =
    dateStamp + '/' + region + '/' + service + '/' + 'aws4_request'
  const canonicalQuerystring = [
    'X-Amz-Algorithm=AWS4-HMAC-SHA256',
    '&X-Amz-Credential=' +
      encodeURIComponent(accessKey + '/' + credentialScope),
    '&X-Amz-Date=' + amzdate,
    '&X-Amz-SignedHeaders=host'
  ].join('')

  const canonicalHeaders = 'host:' + host + '\n'
  const payloadHash = sha256('')
  const canonicalRequest =
    method +
    '\n' +
    canonicalUri +
    '\n' +
    canonicalQuerystring +
    '\n' +
    canonicalHeaders +
    '\nhost\n' +
    payloadHash

  const stringToSign =
    algorithm +
    '\n' +
    amzdate +
    '\n' +
    credentialScope +
    '\n' +
    sha256(canonicalRequest)
  const signingKey = getSignatureKey(secretKey, dateStamp, region, service)
  const signature = sign(signingKey, stringToSign)

  const canonicalQuerystring2 =
    canonicalQuerystring + '&X-Amz-Signature=' + signature
  return 'wss://' + host + canonicalUri + '?' + canonicalQuerystring2
}
