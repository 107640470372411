import illustTopBluePng from 'src/assets/images/schedule/illust_top_blue.png'
import iconMagnetBluePng from 'src/assets/images/schedule/icon_magnet_blue.png'
import iconMagnetGreenPng from 'src/assets/images/schedule/icon_magnet_green.png'
import iconMagnetRedPng from 'src/assets/images/schedule/icon_magnet_red.png'
import iconMagnetYellowPng from 'src/assets/images/schedule/icon_magnet_yellow.png'
import sampleLogoPng from 'src/assets/images/schedule/sample_logo.png'
import stationaryPng from 'src/assets/images/schedule/stationary.png'
import { FloatingText } from '../../FloatingText'
import { Schedule } from 'generated/backendSchemas'

export const PatternC = ({ schedule }: Schedule) => {
  return (
    <div className="schedule flatPanel vertical">
      <img
        className="illust illust__top"
        src={illustTopBluePng}
        alt=""
      />
      <header className="header">
        <div className="header__title">
          <h1>ご近隣の皆さまへ</h1>
          <p>-&nbsp;今週の作業予定&nbsp;-</p>
        </div>
      </header>
      <div className="contentWrapper">
        <div className="content">
          <div className="content__mag content__mag--red">
            <img src={iconMagnetRedPng} alt="" />
          </div>
          <div className="content__mag content__mag--blue">
            <img src={iconMagnetBluePng} alt="" />
          </div>
          <div className="content__mag content__mag--yellow">
            <img src={iconMagnetYellowPng} alt="" />
          </div>
          <div className="content__mag content__mag--green">
            <img src={iconMagnetGreenPng} alt="" />
          </div>
          <ul className="scheduleList">
            {(schedule ?? []).map((item, index) => {
              const additionalClass =
                index === 0
                  ? 'scheduleList__item--holiday'
                  : index === 5
                  ? 'scheduleList__item--sat'
                  : index === 6
                  ? 'scheduleList__item--sun'
                  : ''
              return (
                <li
                  key={item.date}
                  className={`scheduleList__item ${additionalClass}`}
                >
                  <p className="scheduleList__date">{item.date}</p>
                  <p className="scheduleList__dayOfWeek">{item.day_of_week}</p>
                  <p className="scheduleList__plan">{item.schedule_text}</p>
                </li>
              )
            })}
          </ul>
          <img className="stationary" src={stationaryPng} alt="" />
        </div>
        <footer className="footer">
          <div className="footer__content">
            <div className="footer__contentMag footer__contentMag--red">
              <img src={iconMagnetRedPng} alt="" />
            </div>
            <div className="footer__contentMag footer__contentMag--blue">
              <img src={iconMagnetBluePng} alt="" />
            </div>
            <div className="footer__contentMag footer__contentMag--yellow">
              <img
                src={iconMagnetYellowPng}
                alt=""
              />
            </div>
            <div className="footer__contentMag footer__contentMag--green">
              <img src={iconMagnetGreenPng} alt="" />
            </div>
            <div className="footer__contentWrapper">
              <div className="footer__logo">
                <img src={sampleLogoPng} alt="企業ロゴ" />
              </div>
              <FloatingText>
                ゲート付近を通過する際は注意してお通りください。お気づきの点は右記までご連絡ください。0120-123-123
              </FloatingText>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
