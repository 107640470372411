import { ExamplePageDefault } from './Default'
import { ExamplePageEva } from './Eva'
import './index.scss'
import { Icc } from 'generated/backendSchemas'

export type Props = {
  type: 'default' | 'eva'
  data: Icc
}

export const Presenter = ({ type, data }: Props) => {
  switch (type) {
    case 'default':
      return <ExamplePageDefault {...data} />
    case 'eva':
      return <ExamplePageEva />
    default: {
      const unknown: never = type
      throw new Error(unknown)
    }
  }
}
