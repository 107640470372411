import React, { createContext, useContext, useState } from 'react'

const authContext = createContext<{ token: null | string }>({
  token: null
})

const setAuthContext = createContext<{
  setToken: (token: string | null) => void
}>({
  setToken: () => undefined
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, _setToken] = useState<string | null>(
    localStorage.getItem('token')
  )

  const setToken = (token: string | null) => {
    if (token) {
      localStorage.setItem('token', token)
    } else {
      localStorage.removeItem('token')
    }
    return _setToken(token)
  }

  return (
    <authContext.Provider value={{ token }}>
      <setAuthContext.Provider value={{ setToken }}>
        {children}
      </setAuthContext.Provider>
    </authContext.Provider>
  )
}

export const useAuthContext = () => {
  const { token } = useContext(authContext)

  return token
}

export const useSetAuthContext = () => {
  const { setToken } = useContext(setAuthContext)

  return setToken
}
