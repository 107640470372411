import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect
} from 'react'
import { createPortal } from 'react-dom'
import { Props as ToastProps, Toast } from 'src/components/molecules/toast'

type Props = {
  showToast: (props: ToastProps) => void
}

const ToastContext = createContext<Props>({
  showToast: () => {}
})

export const useToast = () => {
  return useContext(ToastContext)
}

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [isShow, setIsShow] = useState(false)
  const [toastState, setToastState] = useState<ToastProps>({
    text: '',
    type: 'alert'
  })

  const showToast = (data: ToastProps) => {
    setToastState(data)
    setIsShow(true)
  }

  const hideToast = () => setIsShow(false)

  useEffect(() => {
    const timerId = setTimeout(hideToast, 5000)
    return () => clearTimeout(timerId)
  }, [toastState])

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {isShow && createPortal(<Toast {...toastState} />, document.body)}
    </ToastContext.Provider>
  )
}
