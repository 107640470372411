import { ExtendedNoiseVibration } from '../types'
import { ExamplePageDefault } from './Default'
import { ExamplePageEva } from './Eva'
import './index.scss'
import { PatternC } from './PatternC'
import { PatternD } from './PatternD'

export type Props = {
  type: 'default' | 'eva' | 'patternC' | 'patternD'
  data: ExtendedNoiseVibration
}

export const Presenter = ({ type, data }: Props) => {
  switch (type) {
    case 'default':
      return <ExamplePageDefault {...data} />
    case 'eva':
      return <ExamplePageEva {...data} />
    case 'patternC':
      return <PatternC {...data} />
    case 'patternD':
      return <PatternD {...data} />
    default: {
      const unknown: never = type
      throw new Error(unknown)
    }
  }
}
