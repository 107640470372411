import icon01dPng from 'src/assets/images/weather-forecast/eva/icon_weather/01d.png'
// import icon02dPng from "src/assets/images/weather-forecast/eva/icon_weather/02d.png";
// import icon03dPng from "src/assets/images/weather-forecast/eva/icon_weather/03d.png";
// import icon04dPng from "src/assets/images/weather-forecast/eva/icon_weather/04d.png";
// import icon09dPng from "src/assets/images/weather-forecast/eva/icon_weather/09d.png";
// import icon10dPng from "src/assets/images/weather-forecast/eva/icon_weather/10d.png";
// import icon11dPng from "src/assets/images/weather-forecast/eva/icon_weather/11d.png";
// import icon13dPng from "src/assets/images/weather-forecast/eva/icon_weather/13d.png";
// import icon50dPng from "src/assets/images/weather-forecast/eva/icon_weather/50d.png";
import iconSafetyPng from 'src/assets/images/weather-forecast/eva/icon_wbgt/safety.png'
// import iconAttentionPng from "src/assets/images/weather-forecast/eva/icon_wbgt/attention.png";
// import iconVigilancePng from "src/assets/images/weather-forecast/eva/icon_wbgt/vigilance.png";
// import iconSvigilancePng from "src/assets/images/weather-forecast/eva/icon_wbgt/s-vigilance.png";
// import iconDangerPng from "src/assets/images/weather-forecast/eva/icon_wbgt/danger.png";
import { Weather } from 'generated/backendSchemas'
import { Util } from 'src/utils'

export const ExamplePageEva = ({ weather }: Weather) => {
  return (
    <div className="weatherForecast eva vertical">
      <header className="header">
        <h1 className="header__title header__title--eva">
        <span className='header__spotName'>埼玉県</span>
        週間天気予報
        </h1>
      </header>
      <div className="content">
        <ul className="weatherList weatherList--eva">
          {(weather ?? []).map((item, index) => {
            const additionalClass =
              index === 5
                ? 'weatherList__dayOfWeek--sat'
                : index === 6
                ? 'weatherList__dayOfWeek--sun'
                : ''
            const caption = Util.wbgt.getCaptionByWbgt(item.wbgt_level ?? 0)
            return (
              <li key={index} className="weatherList__item">
                <p className="weatherList__day">{item.date}</p>
                <p className={`weatherList__dayOfWeek ${additionalClass}`}>
                  {item.day_of_week}
                </p>
                <div className="weatherList__dayOfWeather">
                  {/* TODO: 出しわけする */}
                  <img src={item.weather_mark} alt="晴れ" />
                </div>
                <div className="weatherList__temperature">
                  <p className="weatherList__temperature--max">
                    {item.highest}
                    <span>℃</span>
                  </p>
                  <p className="weatherList__temperature--min">
                    {item.lowest}
                    <span>℃</span>
                  </p>
                </div>
                <div className="weatherList__rainyPercent">
                  <p className="weatherList__rainyPercent--title">
                    降水
                    <br />
                    確率
                  </p>
                  <p className="weatherList__rainyPercent--value">
                    {item.precipitation_percentage}
                    <span>%</span>
                  </p>
                </div>
                <div className="weatherList__wbgt">
                  <div className="weatherList__wbgt--icon">
                    {/* TODO: 出しわけする */}
                    <img src={iconSafetyPng} alt={caption} />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
