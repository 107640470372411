import { useGetWeather } from 'generated/backendComponents'
import { Loading } from 'src/components/atoms/Loading'
import { usePrefetch, useWindowResize } from 'src/hooks'
import { Presenter as HorizontalPresenter } from './Horizontal/Presenter'
import { Presenter as VerticalPresenter } from './Vertical/Presenter'

type Props = {
  slideIndex: number
  contentId: number
}

export const WeatherPage = ({ contentId, slideIndex }: Props) => {
  const { data, refetch } = useGetWeather(
    {
      pathParams: { contentId: String(contentId) }
    },
    { enabled: false }
  )
  usePrefetch({ prefetch: refetch, slideIndex })

  const { isLandScape } = useWindowResize()

  // const { showToast } = useToast()

  // useRenderEffect(() => {
  //   showToast({ text: 'test', type: 'alert' })
  // })

  // TODO: 設定値を取得
  const type = 'default' as any

  if (!data) return <Loading />

  return isLandScape ? (
    <HorizontalPresenter {...{ type, data }} />
  ) : (
    <VerticalPresenter {...{ type, data }} />
  )
}
