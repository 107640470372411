import titleBlackBoard from 'src/assets/images/weather-forecast/blackBoard/title_blackBoard.png'
import blackBoardBottom from 'src/assets/images/weather-forecast/blackBoard/blackBoard_bottom.jpg'
import illustTopWhitePng from 'src/assets/images/weather-forecast/blackBoard/illust_top_white.png'
import illustTownWhitePng from 'src/assets/images/weather-forecast/blackBoard/illust_town_white.png'
import icon01dPng from 'src/assets/images/weather-forecast/icon_weather/01d.png'
// import icon02dPng from "src/assets/images/weather-forecast/icon_weather/02d.png";
// import icon03dPng from "src/assets/images/weather-forecast/icon_weather/03d.png";
// import icon04dPng from "src/assets/images/weather-forecast/icon_weather/04d.png";
// import icon09dPng from "src/assets/images/weather-forecast/icon_weather/09d.png";
// import icon10dPng from "src/assets/images/weather-forecast/icon_weather/10d.png";
// import icon11dPng from "src/assets/images/weather-forecast/icon_weather/11d.png";
// import icon13dPng from "src/assets/images/weather-forecast/icon_weather/13d.png";
// import icon50dPng from "src/assets/images/weather-forecast/icon_weather/50d.png";
import iconSafetyPng from 'src/assets/images/weather-forecast/icon_wbgt/safety.png'
// import iconAttentionPng from "src/assets/images/weather-forecast/icon_wbgt/attention.png";
// import iconVigilancePng from "src/assets/images/weather-forecast/icon_wbgt/vigilance.png";
// import iconSvigilancePng from "src/assets/images/weather-forecast/icon_wbgt/s-vigilance.png";
// import iconDangerPng from "src/assets/images/weather-forecast/icon_wbgt/danger.png";
import rainyPercentIconPng from 'src/assets/images/weather-forecast/blackBoard/rainy_percent_icon.png'
import { Weather } from 'generated/backendSchemas'
import { Util } from 'src/utils'

export const ExamplePageDefault = ({ weather }: Weather) => {
  return (
    <div className="weatherForecast default horizontal">
      <header className="header">
        <h1 className="header__title">
          <span className='header__spotName'>埼玉県</span>
          <img src={titleBlackBoard} alt="週間天気予報" />
        </h1>
      </header>
      <div className="illust illust__top">
        <img src={illustTopWhitePng} alt="" />
      </div>
      <div className="content content--default">
        <ul className="weatherList">
          {(weather ?? []).map((item, index) => {
            const additionalClass =
              index === 0
                ? 'weatherList__item--holiday'
                : index === 5
                ? 'weatherList__item--sat'
                : index === 6
                ? 'weatherList__item--sun'
                : ''
            const caption = Util.wbgt.getCaptionByWbgt(item.wbgt_level ?? 0)
            return (
              <li key={index} className={`weatherList__item ${additionalClass}`}>
                <p className="weatherList__day">{item.date}</p>
                <p className="weatherList__dayOfWeek">
                  {item.day_of_week}
                </p>
                <div className="weatherList__dayOfWeather">
                  <img src={item.weather_mark} alt="晴れ" />
                </div>
                <div className="weatherList__temperature">
                  <p className="weatherList__temperature--min">
                    {item.lowest}
                    <span>℃</span>
                  </p>
                  <p className="weatherList__temperature--max">
                    {item.highest}
                    <span>℃</span>
                  </p>
                </div>
                <div className="weatherList__rainyPercent">
                  <div className="weatherList__rainyPercent--icon">
                    {/* TODO: 出しわけする */}
                    <img src={rainyPercentIconPng} alt="" />
                  </div>
                  <p className="weatherList__rainyPercent--value">
                    {item.precipitation_percentage}
                    <span>%</span>
                  </p>
                </div>
                <div className="weatherList__wbgt">
                  <div className="weatherList__wbgt--icon">
                    <img src={iconSafetyPng} alt={caption} />
                  </div>
                  <p
                    className={`weatherList__wbgt--value ${Util.wbgt.getClassNameByWbgt(
                      item.wbgt_level ?? 0
                    )}`}
                  >
                    {caption}
                  </p>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="illust illust__town">
        <img src={illustTownWhitePng} alt="" />
      </div>
      <div className="illust illust__bottom">
        <img src={blackBoardBottom} alt="" />
      </div>
    </div>
  )
}
