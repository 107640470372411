import { useLiveQuery } from 'dexie-react-hooks'
import { useEffect, useMemo, useState } from 'react'
import { db } from 'src/db'
import { useSwiperSlide } from 'swiper/react'

export const useGetVideUrlQuery = (filePath: string | undefined) => {
  const video = useLiveQuery(
    async () => await db.videos.get({ filePath: filePath ?? '' }),
    [filePath]
  )
  const videoUrl = useMemo(() => {
    if (!video?.blob) return
    return URL.createObjectURL(video.blob)
  }, [video?.blob])

  return videoUrl
}

// NOTE: videoタグを強制的に再レンダリングする(動作を安定させるため)
export const useUpdateKeyWhenCurrentSlide = () => {
  const [key, setKey] = useState(0)
  const swiperSlide = useSwiperSlide()
  useEffect(() => {
    if (swiperSlide.isActive) setKey((state) => ++state)
  }, [swiperSlide.isActive])

  return key
}
