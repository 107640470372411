import illustTopPng from 'src/assets/images/noise-meter/flatPanel/illust_top.png'
import illustCouplePng from 'src/assets/images/noise-meter/flatPanel/illust_couple.png'
import illustFamilyPng from 'src/assets/images/noise-meter/flatPanel/illust_family.png'
import illustTownPng from 'src/assets/images/noise-meter/flatPanel/illust_town.png'
import iconNoisePng from 'src/assets/images/noise-meter/flatPanel/icon_noise.png'
import iconVibrationPng from 'src/assets/images/noise-meter/flatPanel/icon_vibration.png'
import dbPng from 'src/assets/images/noise-meter/flatPanel/db.png'
import { formatDate } from 'src/utils/formatDate'
import { FloatingText } from '../../FloatingText'
import { ExtendedNoiseVibration } from '../../types'

export const PatternC = ({
  displayDate,
  noiseValue,
  vibrationValue
}: ExtendedNoiseVibration) => {
  return (
    <div className="noiseMeter flatPanel vertical">
      <header className="header">
        <h1 className="header__title">現在の騒音・振動</h1>
        <p className="header__date">
          {displayDate && formatDate(new Date(displayDate), 'M月d日H:mm時点')}
        </p>
      </header>
      <FloatingText>日本橋一丁目中地区第一種市街地再開発事業日本橋一丁目中地区第一種市街地再開発事業</FloatingText>
      <div className="illust illust__top">
        <img src={illustTopPng} alt="" />
      </div>
      <div className="illust illust__couple">
        <img src={illustCouplePng} alt="" />
      </div>
      <div className="illust illust__family">
        <img src={illustFamilyPng} alt="" />
      </div>
      <div className="dataWrapper">
        <div className="noiseWrapper">
          <h2 className="noiseWrapper__title">
            <img src={iconNoisePng} alt="騒音" />
          </h2>
          <div className="valueWrapper">
            <p className="valueWrapper__strings">{noiseValue}</p>
            <div className="valueWrapper__db">
              <img src={dbPng} alt="デシベル" />
            </div>
          </div>
        </div>

        <div className="vibrationWrapper">
          <h2 className="vibrationWrapper__title">
            <img src={iconVibrationPng} alt="振動" />
          </h2>
          <div className="valueWrapper">
            <p className="valueWrapper__strings">{vibrationValue}</p>
            <div className="valueWrapper__db">
              <img src={dbPng} alt="デシベル" />
            </div>
          </div>
        </div>
      </div>
      <div className="illust illust__town">
        <img src={illustTownPng} alt="" />
      </div>
    </div>
  )
}
