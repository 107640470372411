import { Util } from 'src/utils'
import { formatDate } from 'src/utils/formatDate'
import { Props } from '../../types'
import { getCharacterImagesByWbgt, getTextImagesByWbgt } from './utils'

export const HeatStrokePageDefault = ({ data }: Props) => {
  const wbgt = data.value ?? 0
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  const characterImages = getCharacterImagesByWbgt(wbgt)
  const textImages = getTextImagesByWbgt(wbgt)
  const className = Util.wbgt.getClassNameByWbgt(wbgt)

  return (
    <div className="heatstroke default horizontal">
      <header>
        <h1 className="content_title">暑さ指数[WBGT]</h1>
        <p className="update_time">
          {data.display_date &&
            formatDate(new Date(data.display_date), 'M月d日H:mm時点')}
        </p>
      </header>
      <main className="content_body">
        <div className={`content_wrapper ${className}`}>
          <div className="content_inner">
            <div className="value_icon">
              <img
                src={characterImages[0]}
                srcSet={`${characterImages[0]} 1x, ${characterImages[1]} 2x`}
                loading="lazy"
                decoding="async"
                width="586"
                height="768"
              />
            </div>
            <div className="value_detail">
              <div className="value_header">
                <p className="numerical_value">
                  WBGT値<span className="value">{wbgt}</span>
                </p>
                <p className={`vigilance_level ${className}`}>
                  警戒レベル<span className="value">{dangerLevel}</span>
                </p>
              </div>
              <div className="value_content">
                <img
                  src={textImages[0]}
                  srcSet={`${textImages[0]} 1x, ${textImages[1]} 2x`}
                  loading="lazy"
                  decoding="async"
                  width="977"
                  height="321"
                />
              </div>
              <div className="value_text">
                <p>{Util.wbgt.getDescriptionByWbgt(wbgt)}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
