import debounce from 'lodash.debounce'
import { useState } from 'react'
import { useRenderEffect } from './useRenderEffect'

export const useWindowResize = () => {
  const [isLandScape, setIsLandScape] = useState(true)

  useRenderEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setIsLandScape(window.innerWidth > window.innerHeight)
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return { isLandScape } as const
}
