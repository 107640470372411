import { Props } from '../../types'
import { Util } from 'src/utils'
import { getImagesByWbgt } from '../../Horizontal/Eva/utils'
import { formatDate } from 'src/utils/formatDate'

export const Eva = ({ data }: Props) => {
  const wbgt = data.value ?? 0
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  const images = getImagesByWbgt(wbgt)
  const className = Util.wbgt.getClassNameByWbgt(wbgt)

  return (
    <div className={`heatstroke eva vertical  ${className}`}>
      <img className="bar_top" src={images.top} alt="" height="24" />
      <img className="bar_bottom" src={images.bottom} alt="" height="24" />
      <header>
        <h1 className="content_title">暑さ指数[WBGT]</h1>
        <p className="update_time">
          {data.display_date &&
            formatDate(new Date(data.display_date), 'M月d日H:mm時点')}
        </p>
      </header>
      <main>
        <div className="content_header">
          <p className="numerical_value">
            WBGT値<span className="value">{wbgt}</span>
          </p>
        </div>
        <div className="content_body">
          <p className="value_content">{Util.wbgt.getCaptionByWbgt(wbgt)}</p>
          <p className={`vigilance_level ${className}`}>
            警戒レベル<span className="value">{dangerLevel}</span>
          </p>
        </div>
        <div className="content_footer">
          <p className="content_text">{Util.wbgt.getDescriptionByWbgt(wbgt)}</p>
        </div>
      </main>
    </div>
  )
}
