import barTopSVigilancePng from 'src/assets/images/heatstroke/eva/bar_top_s-vigilance.png'
import barBottomSVigilancePng from 'src/assets/images/heatstroke/eva/bar_bottom_s-vigilance.png'
import barTopSafetyPng from 'src/assets/images/heatstroke/eva/bar_top_safety.png'
import barBottomSafetyPng from 'src/assets/images/heatstroke/eva/bar_bottom_safety.png'
import barTopAttention from 'src/assets/images/heatstroke/eva/bar_top_attention.png'
import barBottomAttention from 'src/assets/images/heatstroke/eva/bar_bottom_attention.png'
import barTopVigilance from 'src/assets/images/heatstroke/eva/bar_top_vigilance.png'
import barBottomVigilance from 'src/assets/images/heatstroke/eva/bar_bottom_vigilance.png'
import barTopDanger from 'src/assets/images/heatstroke/eva/bar_top_danger.png'
import barBottomDanger from 'src/assets/images/heatstroke/eva/bar_bottom_danger.png'
import { Util } from 'src/utils'

export const getImagesByWbgt = (
  wbgt: number
): { top: string; bottom: string } => {
  const dangerLevel = Util.wbgt.getDangerLevel(wbgt)
  switch (dangerLevel) {
    case 5:
      return { top: barTopDanger, bottom: barBottomDanger }
    case 4:
      return { top: barTopVigilance, bottom: barBottomVigilance }
    case 3:
      return { top: barTopSVigilancePng, bottom: barBottomSVigilancePng }
    case 2:
      return { top: barTopAttention, bottom: barBottomAttention }
    case 1:
      return { top: barTopSafetyPng, bottom: barBottomSafetyPng }
    default: {
      const unknown: never = dangerLevel
      throw new Error(unknown)
    }
  }
}
