import { GetSlideshowResponse } from 'generated/backendComponents'
import { MoviePage } from 'src/components/pages/movie'
import { PicturePage } from 'src/components/pages/picture'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { HeatstrokePage } from '../../components/pages/heatstroke'
import { NoisePage } from '../../components/pages/noise'
import { SchedulePage } from '../../components/pages/schedule'
import { WeatherPage } from '../../components/pages/weather_forecast'
import { WeatherGaugePage } from 'src/components/pages/weather_gauge'
import { useDownloadVideoFromGetSlideshowResponse, useSlideshow } from './hooks'
import { Loading } from 'src/components/atoms/Loading'

export type ResponseSlide = NonNullable<GetSlideshowResponse['slides']>[number]

SwiperCore.use([Autoplay])

type SlideProps = {
  responseSlide: ResponseSlide
  slideIndex: number
}

const Slide = ({ slideIndex, responseSlide: { id, type } }: SlideProps) => {
  switch (type) {
    case 'schedule':
      return <SchedulePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'weather':
      return <WeatherPage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'noise_vibration':
      return <NoisePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'wbgt':
      return <HeatstrokePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'icc':
      return <WeatherGaugePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'picture':
      return <PicturePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'movie':
      return <MoviePage contentId={id ?? 0} slideIndex={slideIndex} />
    case 'html':
    case 'camera':
      return (
        <div>
          <div>まだ未実装です</div>
          <div>type: {type}</div>
        </div>
      )
    case undefined:
      return <div>typeが未定義です</div>
    default: {
      const strangeValue: never = type
      throw new Error(strangeValue)
    }
  }
}

export const Slideshow = () => {
  const {
    getSlideshowResponse,
    handleSlideChange,
    handleTouchStart,
    handleTouchEnd,
    handleAutoplay
  } = useSlideshow()

  useDownloadVideoFromGetSlideshowResponse(getSlideshowResponse)
  if (getSlideshowResponse?.slides == null) return <Loading />

  const slides = getSlideshowResponse.slides.filter((slide) => slide.type)

  return (
    <div style={{ height: '100vh' }}>
      <Swiper
        autoplay
        loop={false} // REF: https://zenn.dev/attt/articles/swiper-loop-without-duplicate
        onSlideChange={handleSlideChange}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onAutoplay={handleAutoplay}
        style={{ height: '100%' }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={slide.id ?? index}
            data-swiper-autoplay={(slide.duration ?? 1) * 1000}
          >
            <Slide responseSlide={slide} slideIndex={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
