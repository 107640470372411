export const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '48px',
        height: '100vh'
      }}
    >
      更新中
    </div>
  )
}
