import { useGetPicture } from 'generated/backendComponents'
import { Loading } from 'src/components/atoms/Loading'
import { usePrefetch } from 'src/hooks'
import { Presenter } from './Presenter'

type Props = {
  slideIndex: number
  contentId: number
}

export const PicturePage = ({ slideIndex, contentId }: Props) => {
  const { data, refetch } = useGetPicture(
    {
      pathParams: { contentId: String(contentId) }
    },
    { enabled: false }
  )
  usePrefetch({ prefetch: refetch, slideIndex })

  if (!data) return <Loading />

  return <Presenter image_file={data?.image_file} />
}
