import { useGetMovie } from 'generated/backendComponents'
import { useEffect, useRef } from 'react'
import { Loading } from 'src/components/atoms/Loading'
import { usePrefetch } from 'src/hooks'
import { useSwiperSlide } from 'swiper/react'
import { useGetVideUrlQuery, useUpdateKeyWhenCurrentSlide } from './hooks'
import { Presenter } from './Presenter'

type Props = {
  slideIndex: number
  contentId: number
}

export const MoviePage = ({ slideIndex, contentId }: Props) => {
  const { data, refetch, status } = useGetMovie(
    {
      pathParams: { contentId: String(contentId) }
    },
    { enabled: false }
  )
  usePrefetch({ prefetch: refetch, slideIndex })

  const key = useUpdateKeyWhenCurrentSlide()
  const videoUrl = useGetVideUrlQuery(data?.movie_file)

  if (status === 'loading' || !data || !videoUrl) return <Loading />

  return <Inner key={key} url={videoUrl} />
}

const Inner = ({ url }: { url: string }) => {
  const swiperSlide = useSwiperSlide()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const func = async () => {
      if (!videoRef.current) return

      if (!swiperSlide.isActive) {
        videoRef.current.pause()
        return
      }

      // すでに再生中ならreturn
      if (!videoRef.current.paused) return

      // 再生する準備ができていなければreturn
      if (videoRef.current.readyState !== 4) return
      videoRef.current.play()
    }

    const interval = setInterval(() => {
      func()
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [swiperSlide.isActive, url])

  return <Presenter ref={videoRef} url={url} />
}
