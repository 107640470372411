import Dexie, { Table } from 'dexie'

export type Video = {
  filePath: string
  blob: Blob
}

export class MySubClassedDexie extends Dexie {
  videos!: Table<Video>

  constructor() {
    super('myDatabase')
    this.version(1).stores({
      videos: 'filePath, blob'
    })
  }
}

export const db = new MySubClassedDexie()
