import evaBarJpg from 'src/assets/images/schedule/eva_bar.jpg'
import sampleLogoPng from 'src/assets/images/schedule/sample_logo.png'
import { FloatingText } from '../../FloatingText'
import { Schedule } from 'generated/backendSchemas'
import { getAdditionalClass } from '../../util'

export const SchedulePageEva = ({ schedule }: Schedule) => {
  return (
    <div className="schedule eva vertical">
      <img
        className="bar bar__top"
        src={evaBarJpg}
        alt=""
      />
      <header className="header header--eva">
        <div className="schedule__title">
          <h1>ご近隣の皆さまへ</h1>
          <p>-&nbsp;今週の作業予定&nbsp;-</p>
        </div>
      </header>
      <div className="contentWrapper">
        <div className="content content--eva">
        <ul className="scheduleList scheduleList--eva">
            {(schedule ?? []).map((item, index) => {
              const additionalClass =
                index === 0
                ? 'scheduleList__item--holiday'
                : index === 5
              ? 'scheduleList__item--sat'
              : index === 6
              ? 'scheduleList__item--sun'
              : ''
              return (
                <li
                  key={item.date}
                  className={`scheduleList__item scheduleList__item--eva ${additionalClass}`}
                >
                  <p className="scheduleList__date">{item.date}</p>
                  <p className="scheduleList__dayOfWeek">{item.day_of_week}</p>
                  <p className="scheduleList__plan">{item.schedule_text}</p>
                </li>
              )
            })}
          </ul>
        </div>
        <footer className="footer">
          <div className="footer__content footer__content--eva">
            <div className="footer__contentWrapper">
              <div className="footer__logo">
                <img src={sampleLogoPng} alt="企業ロゴ" />
              </div>
              <FloatingText isEva>
                ゲート付近を通過する際は注意してお通りください。お気づきの点は右記までご連絡ください。0120-123-123
              </FloatingText>
            </div>
          </div>
        </footer>
      </div>
      <img
        className="bar bar__bottom"
        src={evaBarJpg}
        alt=""
      />
    </div>
  )
}
