import { Picture } from 'generated/backendSchemas'

export const Presenter = ({ image_file }: Pick<Picture, 'image_file'>) => {
  return (
    <img
      style={{ height: '100vh', width: '100%', objectFit: 'cover' }}
      src={image_file}
    />
  )
}
