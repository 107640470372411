import './index.scss'
import IconToastAlert from 'src/assets/images/toast/icon_toast_alert.png'
import IconToastDelivery from 'src/assets/images/toast/icon_toast_delivery.png'
import IconToastTime from 'src/assets/images/toast/icon_toast_time.png'

type ToastType = 'alert' | 'delivery' | 'time'

export type Props = {
  type: ToastType
  text: string
}

const IconByType: Record<ToastType, string> = {
  alert: IconToastAlert,
  delivery: IconToastDelivery,
  time: IconToastTime
}

export const Toast = ({ text, type }: Props) => {
  return (
    <div id="js-toast" className="toastWrapper">
      <div className={`toastWrapper__content toastWrapper__content--${type}`}>
        <img src={IconByType[type]} alt="警報のアイコン" />
        <p>{text}</p>
      </div>
    </div>
  )
}
