import { useHashLogin } from 'generated/backendComponents'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRenderEffect } from 'src/hooks'
import { useAuthContext, useSetAuthContext } from 'src/contexts/AuthContext'

export const Auth = () => {
  const token = useAuthContext()
  const setAuth = useSetAuthContext()

  const { code } = useParams()
  const navigate = useNavigate()

  const [success, setSuccess] = useState(false)

  useRenderEffect(() => {
    code && localStorage.setItem('code', code)
    setAuth(null)
  })

  useHashLogin(
    { queryParams: { code } },
    {
      onSuccess: (data) => {
        if (!data.token) return
        setAuth(data.token)
        setSuccess(true)
      },
      retry: true,
      retryDelay: 1000 * 60 * 3,
      cacheTime: 0
    }
  )

  useEffect(() => {
    if (token && success) {
      navigate('/slideshow')
    }
  }, [navigate, token, success])

  return <div>認証中...</div>
}
