import { Wbgt } from 'generated/backendSchemas'
import { HeatStrokePageDefault } from './Default'
import { HeatStrokePageEva } from './Eva'
import './index.scss'

export type Props = {
  type: 'default' | 'eva'
  data: Wbgt
}

export const Presenter = ({ type, data }: Props) => {
  switch (type) {
    case 'default':
      return <HeatStrokePageDefault {...{ data }} />
    case 'eva':
      return <HeatStrokePageEva {...{ data }} />
    default: {
      const unknown: never = type
      throw new Error(unknown)
    }
  }
}
